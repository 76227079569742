<template>
  <a-spin :spinning="loadingCalls !== 0" size="large">
    <div>
      <a-card :bordered="true" class="ant-card-no-padding">
        <StandardizationReportButton v-if="showStandardizationOutcome" :enumerator-disabled="enumeratorDisabled" :group-id="groupId" class="ml-10 mt-10"/>
        <div :class="allowDelete ? 'allow-delete group-enumerator-headers txt-black txt-uppercase' : 'group-enumerator-headers txt-black txt-uppercase'">
          <div>
            {{ $t('values.name') }}
          </div>
          <div>
            {{ $t('components.titles.heightOutcome') }}
            <div class="txt-grey txt-10">
              ({{ $t('components.titles.temBias') }}<BiasMeasurement :tem-bias="temBias" property="height"/>)
            </div>
          </div>
          <div>
            {{ $t('components.titles.weightOutcome') }}
            <div class="txt-grey txt-10">
              ({{ $t('components.titles.temBias') }}<BiasMeasurement :tem-bias="temBias" property="weight"/>)
            </div>
          </div>
          <div>
            {{ $t('components.titles.muacOutcome') }}
            <div class="txt-grey txt-10">
              ({{ $t('components.titles.temBias') }}<BiasMeasurement :tem-bias="temBias" property="muac"/>)
            </div>
          </div>
        </div>
        <div v-if="enumerators.length === 0" class="no-enumerators">{{ $t('components.description.noEnumeratorsAdded') }}</div>
        <EnumeratorItem
          v-for="(emumerator, index) in augmentedEnumerators"
          :key="index"
          :show-standardization-outcome="showStandardizationOutcome"
          :allow-delete="allowDelete"
          :on-updated="onUpdate"
          :on-delete="onDelete"
          :has-supervisor="hasSupervisor"
          :enumerator="emumerator"/>
      </a-card>
    </div>
  </a-spin>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'EnumeratorList',
  components: {
    EnumeratorItem: () => import('../EnumeratorItem/enumerator-item.vue'),
    BiasMeasurement: () => import('../BiasMeasurement/index.vue'),
    StandardizationReportButton: () => import('../StandardizationReportButton/index.vue')
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    enumerators: {
      type: Array,
      required: true
    },
    showStandardizationOutcome: {
      type: Boolean,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    onEmpty: {
      type: Function,
      required: true
    },
    enumeratorDisabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      augmentedEnumerators: [],
      temBias: []
    };
  },
  computed: {
    ...mapState({
      standardizationGroupMap: state => state.standardizationGroups.standardizationGroupMap,
      lastTemBiasUpdated: state => state.standardizationGroups.lastTemBiasUpdated,
      loadingCalls: state => state.standardizationGroups.loadingCalls
    })
  },
  watch: {
    enumerators() {
      this.updateAugmentedEnumerators();
    },
    lastTemBiasUpdated: {
      deep: true,
      immediate: true,
      handler() {
        this.getTemBiasFromSelectedGroup();
        this.updateAugmentedEnumerators();
      }
    }
  },
  created() {
    if (this.enumerators) {
      this.updateAugmentedEnumerators();
    }
  },
  mounted() {
    if (this.standardizationGroupMap) {
      this.getTemBiasFromSelectedGroup();
    }
  },
  methods: {
    ...mapActions([
      'removeEnumeratorFromGroup',
      'updateEnumeratorInGroup'
    ]),
    getTemBiasFromSelectedGroup() {
      const groupId = parseInt(this.groupId, 0);
      const groupSelected = this.standardizationGroupMap[groupId];
      if (groupSelected.temBias) {
        this.temBias.length = groupSelected.temBias.length;
        for (let i = 0; i < groupSelected.temBias.length; i += 1) {
          this.$set(this.temBias, i, groupSelected.temBias[i]);
        }
      }
    },
    hasSupervisor(groupId) {
      return this.enumerators.findIndex(
        enumerator => enumerator &&
        enumerator.supervisor &&
        enumerator.groupId === groupId
      ) > -1;
    },
    updateAugmentedEnumerators() {
      for (let i = 0; i < this.enumerators.length; i += 1) {
        const objectToUpdate = {
          ...this.enumerators[i],
          // eslint-disable-next-line no-loop-func
          tembias: this.temBias.filter(temBiasItem => parseInt(temBiasItem.label, 0) === this.enumerators[i].id)
        };

        this.$set(
          this.augmentedEnumerators,
          i,
          objectToUpdate
        );
      }
      // if enumerators decreased in length, we need this to remove old entries
      this.augmentedEnumerators.splice(this.enumerators.length);
    },
    async onUpdate(enumerator, values) {
      const updatedEnumerator = await this.updateEnumeratorInGroup(
        {
          enumeratorId: enumerator.id,
          groupId: enumerator.groupId,
          data: values
        }
      );
      return updatedEnumerator;
    },
    async onDelete(result) {
      await this.removeEnumeratorFromGroup(
        {
          enumeratorId: result.id,
          groupId: result.groupId
        }
      );
      if (this.enumerators.length === 0) {
        this.onEmpty();
      }
    }
  }
};
</script>

<style lang="scss">
  .group-enumerator-headers, .group-enumerator-row {
    display: grid;
    grid-template-columns: 20% 1fr 1fr 1fr 10%;
    grid-template-rows: 1fr;
    gap: 10px 10px;
    font-weight: bold;
    font-family: 'DINNextLTPro-Medium';
    border-bottom: solid 1px #8e908f8e;
    padding: 20px;
    > div  {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

.allow-delete {
  grid-template-columns: 20% 1fr 1fr 1fr 1fr 10%;
}

.disallow-delete {
  grid-template-columns: 20% 1fr 1fr 1fr 10%;
}

.no-enumerators {
  margin: 30px;
  flex-direction: row;
  text-align: center;
  font-size: 16px;
  font-family: 'DINNextLTPro-Regular';
}
</style>
